import {
    Flex,
    Container,
    Heading,
    Stack,
    Text,
    Button,
    Icon,
    IconProps,
    Image,
    AvatarGroup,
    Avatar,
    HStack,
    VStack,
    Link,
  } from '@chakra-ui/react';

  import video from '../assets/video.gif'
  import stars from '../assets/stars.svg'

  const avatars = [
    {
      name: 'Ryan Florence',
      url: 'https://bit.ly/ryan-florence',
    },
    {
      name: 'Segun Adebayo',
      url: 'https://bit.ly/sage-adebayo',
    },
    {
      name: 'Kent Dodds',
      url: 'https://bit.ly/kent-c-dodds',
    },
    {
      name: 'Prosper Otemuyiwa',
      url: 'https://bit.ly/prosper-baba',
    },
    {
      name: 'Christian Nwamba',
      url: 'https://bit.ly/code-beast',
    },
  ];
  
  export default function Hero() {
    return (
      <Container maxW={'5xl'}>
        <Stack
          textAlign={'center'}
          align={'center'}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 15, md: 20 }}>
            <Text fontWeight={'bold'} color={"blue.600"} position={'relative'} bottom={'-30px'}>MVP PLUS</Text>
          <Heading
            fontWeight={700}
            fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
            lineHeight={'110%'}>
            Criamos soluções incríveis com layouts supreendentes
          </Heading>
          <Text color={'gray.500'} maxW={'580px'}>
          Nosso foco é trazer vida para seu software de modo intuitivo e muito bonito. Seu MVP não precisa ficar sem graça.
          </Text>
          <Link href={'https://wa.link/q7zu03'}>
            <Button
              rounded={'md'}
              px={6}
              colorScheme={'orange'}
              bg={'blue.600'}
              _hover={{ bg: 'blue.700' }}>
              Fale Conosco
            </Button>
            </Link>
            <HStack justify={'center'} align={'center'}>
            <AvatarGroup>
              {avatars.map((avatar) => (
                <Avatar
                  key={avatar.name}
                  name={avatar.name}
                  src={avatar.url}
                  size={{ base: 'xs', md: 'sm' }}
                  position={'relative'}
                  zIndex={2}
                  _before={{
                    content: '""',
                    width: 'full',
                    height: 'full',
                    rounded: 'full',
                    transform: 'scale(1.125)',
                    bgGradient: 'linear(to-bl, blue.600,blue.400)',
                    position: 'absolute',
                    zIndex: -1,
                    top: 0,
                    left: 0,
                  }}
                />
              ))}
            </AvatarGroup>
            <VStack justify={'start'} align={'start'}>
                <Image src={stars} />
                <Text fontSize={'sm'}>aprovado por +50 clientes</Text>
            </VStack>
            </HStack>

          <Flex w={'full'} rounded={'3xl'} boxShadow={'2xl'}>
          <Image  width="100%" borderRadius={15}
      viewBox="0 0 702 448" src={video} />
          </Flex>
        </Stack>
      </Container>
    );
  }
  