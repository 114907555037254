import {
    Box,
    Container,
    Image,
    Link,
    SimpleGrid,
    Stack,
    Text,
    useColorModeValue,
  } from '@chakra-ui/react';
  
  import logoImg from '../assets/logo.svg'
  
  const ListHeader = ({ children }) => {
    return (
      <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
        {children}
      </Text>
    );
  };
  
  export default function Footer() {
    return (
      <Box
        bg={useColorModeValue('gray.50', 'gray.900')}
        color={useColorModeValue('gray.700', 'gray.200')}>
        <Container as={Stack} maxW={'6xl'} py={10}>
          <SimpleGrid
            templateColumns={{ sm: '1fr 1fr', md: '2fr 1fr 1fr 1fr 1fr' }}
            spacing={8}>
            <Stack spacing={6}>
              <Box>
              <Image src={logoImg} />
              </Box>
              <Text fontSize={'sm'}>
                © 2023 MVP Plus. Todos os direitos reservados.
              </Text>
            </Stack>
            <Stack align={'flex-start'}>
              <ListHeader>Contato</ListHeader>
              <Link href={'#'}>Fale conosco</Link>
              <Link href={'#'}>Suporte</Link>
              <Link href={'#'}>FAQ</Link>
            </Stack>
            <Stack align={'flex-start'}>
              <ListHeader>Descubra</ListHeader>
              <Link href={'#'}>Sobre nós</Link>
              <Link href={'#'}>Planos</Link>
            </Stack>
            <Stack align={'flex-start'}>
              <ListHeader>Legal info</ListHeader>
              <Link href={'#'}>Política de privacidade</Link>
              <Link href={'#'}>Termos de serviço</Link>
              <Link href={'#'}>Política de Cookies</Link>
            </Stack>
            <Stack align={'flex-start'}>
              <ListHeader>Redes Sociais</ListHeader>
              <Link href={'#'}>Instagram</Link>
              <Link href={'#'}>LinkedIn</Link>
            </Stack>
          </SimpleGrid>
        </Container>
      </Box>
    );
  }