import {
  Box,
  Stack,
  HStack,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
  Button,
  Link,
} from '@chakra-ui/react';

import { CheckCircleIcon } from '@chakra-ui/icons';

import { CloseIcon } from '@chakra-ui/icons';

function PriceWrapper({ children }) {
  return (
    <Box
      mb={4}
      shadow="base"
      borderWidth="1px"
      alignSelf={{ base: 'center', lg: 'flex-start' }}
      borderColor={useColorModeValue('gray.200', 'gray.500')}
      borderRadius={'xl'}>
      {children}
    </Box>
  );
}

export default function ThreeTierPricing() {
  return (
    <Box py={12}
    justify="center"
    align={"center"}>
     <Stack
     maxW={'5xl'}
          textAlign={'center'}
          align={'center'}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 15, md: 20 }}>
            <Text fontWeight={'bold'} color={"blue.600"} position={'relative'} bottom={'-30px'}>CREDIBILIDADE</Text>
          <Heading
            fontWeight={700}
            fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
            lineHeight={'110%'}>
            Confiança que só a MVP Plus pode oferecer!
          </Heading>
          <Text color={'gray.500'} maxW={'580px'}>
          Nosso foco é trazer vida para seu software de modo intuitivo e muito bonito. Seu MVP não precisa ficar sem graça.
          </Text>
          </Stack>
      <Stack
        minH={'450px'}
        direction={{ base: 'column', md: 'row' }}
        textAlign="center"
        justify="center"
        align={"center"}
        spacing={{ base: 4, lg: 10 }}
        py={10}>
            <div data-aos="flip-down">
        <PriceWrapper>
        <Box minH={'450px'} pb={5}>
          <Box py={4} px={12}>
            <Text fontWeight="500" fontSize="2xl">
            Outras Softhouses
            </Text>
            <HStack justifyContent="center">
              <Text fontSize="xl" color="gray.500">
            Mais do mesmo
              </Text>
            </HStack>
          </Box>
          <VStack
            bg={useColorModeValue('gray.50', 'gray.700')}
            py={4}
            borderBottomRadius={'xl'}>
            <List spacing={6} px={12}>
              <ListItem textAlign="start" >
                <ListIcon as={CloseIcon} color="gray.300" />
                Desenvolvimento lento e travado
              </ListItem>
              <ListItem textAlign="start" >
                <ListIcon as={CloseIcon} color="gray.300"/>
                Metodologias ultrapassadas
              </ListItem>
              <ListItem textAlign="start" >
                <ListIcon as={CloseIcon} color="gray.300" />
                Pouca ênfase em UX Design
              </ListItem>
              <ListItem textAlign="start" >
                <ListIcon as={CloseIcon} color="gray.300" />
                Pouca preocupação numa boa interface
              </ListItem>
              <ListItem textAlign="start" >
                <ListIcon as={CloseIcon} color="gray.300" />
                Sem acompanhamento do projeto
              </ListItem>
              
            </List>
            <Box w="80%" pt={7}>
            <Link href={'https://wa.link/q7zu03'}>
                <Button w="full" colorScheme="blue" variant="outline" alignSelf={'center'} justifySelf={'center'} textAlign={'center'}>
                Quero mudar!
              </Button>
              </Link>
            </Box>
          </VStack>
          </Box>
        </PriceWrapper>
        </div>
        <div data-aos="flip-down">
        <PriceWrapper >
          <Box position="relative" minH={'450px'} borderRadius={15}   bgGradient='linear(to-r, #2B6CB0, #6F81F7)' pb={5}>
            <Box
            
              position="absolute"
              top="-16px"
              left="50%"
              style={{ transform: 'translate(-50%)' }}>
              <Text
                textTransform="uppercase"
                bg={'#BEE3F8'}
                px={3}
                py={1}
                color={'gray.800'}
                fontSize="sm"
                fontWeight="700"
                rounded="xl">
                Desenvolva já!
              </Text>
            </Box>
            <Box py={4} px={12} >
              <Text fontWeight="500" fontSize="2xl" color={'white'}>
              Com a MVP Plus
              </Text>
              <HStack justifyContent="center">
                <Text fontSize="2xl" color="gray.100">
                  Supreenda!
                </Text>
              </HStack>
            </Box>
            <VStack
            
              py={4}
              borderBottomRadius={'xl'}>
              <List spacing={6} textAlign="start" px={12} color={'white'}>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color="white.500" />
                  10x mais rápido no desenvolvimento
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color="white.500" />
                  Metodologia 4Ds
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color="white.500" />
                  Serviços até 40% mais econômicos
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color="white.500" />
                  Especialistas em Startups e construção de MVPs
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color="white.500" />
                  Equipe de UX/UI profissionais
                </ListItem>
              </List>
              <Box w="80%" pt={7}>
              <Link href={'https://wa.link/q7zu03'}>
                <Button w="full" color="gray.800"   bg={'#BEE3F8'}>
                  Fale conosco
                </Button>
                </Link>
              </Box>
            </VStack>
          </Box>
        </PriceWrapper>
        </div>
      </Stack>
    </Box>
  );
}