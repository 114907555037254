import {
    Box,
    Button,
    Container,
    Flex,
    Heading,
    Icon,
    Stack,
    Text,
    Link,
    useColorModeValue,
  } from '@chakra-ui/react';

  import { IoIosRocket, IoMdBusiness, IoIosPlayCircle } from "react-icons/io";
  
  import { MdDesignServices } from "react-icons/md";
  
  const Card = ({ heading, description, icon, href, bgcolor }) => {
    return (
      <Box
        maxW={{ base: 'full', md: '275px' }}
        w={'full'}
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        p={5}>
        <Stack align={'start'} spacing={2}>
          <Flex
            w={16}
            h={16}
            align={'center'}
            justify={'center'}
            rounded={'full'}
            bg={bgcolor}>
            {icon}
          </Flex>
          <Box mt={2}>
            <Heading size="md">{heading}</Heading>
            <Text mt={1} fontSize={'sm'}>
              {description}
            </Text>
          </Box>
          <Link href={href}>
          <Button variant={'link'} colorScheme={'blue'} size={'sm'}>
            Saiba mais
          </Button>
          </Link>
        </Stack>
      </Box>
    );
  };
  
  export default function Stacks() {
    return (
      <Box p={4}>
        <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
          <Heading fontSize={{ base: '2xl', sm: '4xl' }} fontWeight={'bold'}>
            Nossas Especialidades
          </Heading>
          <Text color={'gray.600'} fontSize={{ base: 'sm', sm: 'lg' }}>
          Conheça nossas especialidades em desenvolvimento: desenvolvimento web, desenvolvimento mobile, desenvolvimento de software sob medida e desenvolvimento de aplicativos empresariais e muito mais. Entre em contato conosco para saber mais detalhes sobre nossos serviços.
          </Text>
        </Stack>
  
        <Container maxW={'7xl'} mt={12}>
          <Flex flexWrap="wrap" gridGap={6} justify="center">
            <Card
              bgcolor={'#FFF5F5'}
              heading={'Fast'}
              icon={<Icon as={IoIosRocket} w={10} h={10} color={'#C53030'}/>}
              description={
                'Para você que busca validar sua ideia com um protótipo de alta fidelidade.'
              }
              href={'http://mvpfast.mvp.app.br/'}
            />
            <Card
              bgcolor={'#FFF5F7'}
              heading={'Start'}
              icon={<Icon as={IoIosPlayCircle} w={10} h={10} color={'#B83280'} />}
              description={
                'Ideal para aqueles que buscam fazer seu primeiro MVP de maneira veloz.'
              }
              href={'http://start.mvp.app.br/'}
            />
            <Card
              bgcolor={'#EBF8FF'}
              heading={'Business'}
              icon={<Icon as={IoMdBusiness} w={10} h={10} color={'#2B6CB0'} />}
              description={
                'Para aqueles que desejam mudar a regra do jogo com um MVP extremamente robusto.'
              }
              href={'https://business.mvp.app.br/'}
            />
            <Card
              bgcolor={'#FAF5FF'}
              heading={'Plus'}
              icon={<Icon as={MdDesignServices} w={10} h={10} color={'#6B46C1'} />}
              description={
                'Seu MVP com layouts incríveis e interfaces supreendentemente bonitas e modernas.'
              }
              href={'http://plus.mvp.app.br/'}
            />
          </Flex>
        </Container>
      </Box>
    );
  }