import './App.css';
import Header from './components/Header';
import Hero from './components/Hero';
import Softhouse from './components/Section2';
import WhyMvpPlus from './components/Section3';
import InfoProduto from './components/Section4';
import ThreeTierPricing from './components/objectionBreak';
import JoinOurTeam from './components/Contact';
import Footer from './components/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Stacks from './components/Stacks';


function App() {

  AOS.init({
   once: true,
  });
  
  return (
    <>
    <Header />
    <Hero />
    <Softhouse />
    <WhyMvpPlus />
    <Stacks />
    <InfoProduto />
    <ThreeTierPricing />
    <JoinOurTeam />
    <Footer />
    </>
  );
}

export default App;
