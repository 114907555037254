import {
    Flex,
    Container,
    Heading,
    Stack,
    Text,
    Box,
    Button,
    Icon,
    IconProps,
    Image,
    AvatarGroup,
    Avatar,
    HStack,
    VStack,
  } from '@chakra-ui/react';

  import flow from '../assets/flow.svg'


  const avatars = [
    {
      name: 'Ryan Florence',
      url: 'https://bit.ly/ryan-florence',
    },
    {
      name: 'Segun Adebayo',
      url: 'https://bit.ly/sage-adebayo',
    },
    {
      name: 'Kent Dodds',
      url: 'https://bit.ly/kent-c-dodds',
    },
    {
      name: 'Prosper Otemuyiwa',
      url: 'https://bit.ly/prosper-baba',
    },
    {
      name: 'Christian Nwamba',
      url: 'https://bit.ly/code-beast',
    },
  ];
  
  export default function WhyMvpPlus() {
    return (
      <Container maxW={'7xl'}>
        <Stack
          justify={'start'}
          textAlign={'start'}
          align={'start'}
          w={'full'}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 15, md: 20 }}>
            <Text fontWeight={'bold'} color={"blue.600"} position={'relative'} bottom={'-30px'}>MVP PLUS</Text>
          <Heading
            fontWeight={700}
            fontSize={{ base: 'xl', sm: '2xl', md: '4xl' }}
            lineHeight={'110%'}>
            Porque escolher a MVP Plus?
          </Heading>
          <Box bgGradient='linear(to-r, #FC5C7D, #6F81F7)' w={'40%'} h={'5px'} borderRadius={15}></Box>
          <Text color={'gray.500'} maxW={'580px'}>
          Trabalhamos com uma metodologia de desenvolvimento de aplicativos e sistemas até 5x mais rápidos que a média do mercado.
          </Text>

          <Flex w={'full'} >
          <Image  width="100%" borderRadius={15}
      viewBox="0 0 702 448" src={flow} />
          </Flex>
        </Stack>
      </Container>
    );
  }
  