import {
    Container,
    Stack,
    Flex,
    Box,
    Heading,
    Text,
    Button,
    Image,
    Link
} from '@chakra-ui/react';

import wireframes from '../assets/wireframes.svg'

export default function Softhouse() {
    return (
        <Container maxW={'7xl'}>
            <Stack
                align={'center'}
                spacing={{ base: 8, md: 10 }}
                py={{ base: 10, md: 10 }}
                direction={{ base: 'column', md: 'row' }}>
                <Stack flex={1} spacing={{ base: 5, md: 10 }}>
                <Text fontWeight={'bold'} color={"blue.600"} position={'relative'} bottom={{md:'-30px', lg: '-10px'}} textTransform={'uppercase'}   textAlign={{base:'center', md:'start'}}>A Softhouse mais moderna do brasil</Text>

                    <Heading
                        lineHeight={'55px'}
                        fontWeight={700}
                        textAlign={{base:'center', md:'start'}}
                        fontSize={{ base: '2xl', sm: '3xl', lg: '5xl' }}>
                        <div data-aos="fade-up-left">
                            <Text as={'span'} color={'gray.800'}>
                            Criamos soluções incríveis com interfaces supreendentes.
                            </Text>
                        </div>
                    </Heading>
                    <div data-aos="fade-up-left">
                        <Text color={'gray.500'}   textAlign={{base:'center', md:'start'}}>
                        Nós investimos em interfaces atraentes e intuitivas para nossos aplicativos, com abordagem centrada no usuário. Nossos designs melhoram a experiência do usuário e impressionam visualmente.
                        </Text>
                    </div>
                    <Stack
                        spacing={{ base: 4, sm: 6 }}
                        justify={{ base: 'center', md: 'start' }}
                        align={{ base: 'center', md: 'start' }}
                        direction={{ base: 'column', sm: 'row' }}>
                        <div data-aos="fade-up-left">
                        <Link href={'https://wa.link/q7zu03'}>
                            <Button
                                rounded={'md'}
                                size={'lg'}
                                fontWeight={'normal'}
                                px={6}
                                colorScheme={'orange'}
                                bg={'blue.600'}
                                _hover={{ bg: 'blue.700' }}>
                                Fale conosco
                            </Button>
                            </Link>
                        </div>
                    </Stack>
                </Stack>

                <Flex
                    flex={1}
                    justify={'center'}
                    align={'center'}
                    position={'relative'}
                    w={'100%'}>

                    <Box
                        position={'relative'}
                        width={'100%'}
                        justify={'center'}
                        align={'center'}
                    >
                        <div data-aos="fade-up-right">
                            <Image
                                alt={'Hero Image'}
                                fit={'cover'}
                                justify={'center'}
                                align={'center'}
                                w={{ base: '75%', md: '100%' }}
                                h={{ base: '75%', md: '100%' }}
                                src={
                                    wireframes
                                }
                            />
                        </div>
                    </Box>
                </Flex>



            </Stack>
        </Container>
    );
}

